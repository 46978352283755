.App {
  text-align: center;
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.item {
  overflow: hidden;
  transform: translatey(5px);
  animation: float 8s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(5px);
  }

  50% {
    transform: translatey(-5px);
  }

  100% {
    transform: translatey(5px);
  }
}

.crop-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: 75%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.topEditorsShrik {
  max-height: 50vh;
}

.bottomEditorShrik {
  max-height: 50vh;
}

@media (min-width: 1024px) {
  .topEditorsShrik {
    max-height: 100vh;
    min-height: 100vh;
  }
}

@media (min-width: 1024px) {
  .bottomEditorShrik {
    max-height: 100vh;
    min-height: 100vh;
  }
}



.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "1";
}

.ql-picker-label[data-value="8px"]::before {
  content: "1" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "2";
}

.ql-picker-label[data-value="10px"]::before {
  content: "2" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "3";
}

.ql-picker-label[data-value="14px"]::before {
  content: "3" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "4";
}

.ql-picker-label[data-value="16px"]::before {
  content: "4" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "5";
}

.ql-picker-label[data-value="18px"]::before {
  content: "5" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "6";
}

.ql-picker-label[data-value="24px"]::before {
  content: "6" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "7";
}

.ql-picker-label[data-value="30px"]::before {
  content: "7" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "8";
}

.ql-picker-label[data-value="36px"]::before {
  content: "8" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: "9";
}

.ql-picker-label[data-value="48px"]::before {
  content: "9" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="60px"]::before {
  content: "10";
}

.ql-picker-label[data-value="60px"]::before {
  content: "10" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before {
  content: "11";
}

.ql-picker-label[data-value="72px"]::before {
  content: "11" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="84px"]::before {
  content: "12";
}

.ql-picker-label[data-value="84px"]::before {
  content: "12" !important;
}


.fade-in-image {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.calculator {
  height: 100%;
  width: 100%;
}

.calculator>.react-multi-carousel-track {
  height: 100%;
}
