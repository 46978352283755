@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e7eb;

}

code {}


.droppable {
  min-width: 20vw;
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.markdown>* {
  all: revert;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-container {
  font-family: inherit !important;
}

.ql-editor[contenteditable="false"] {
  padding: 0% !important;
}

code,
kbd,
samp,
pre {
  font-family: inherit !important;
}
